:root {
  --primary-color: #0066ff;
  --primary-color-darker: #0052cc;
  --focus-color: orange;
  --dark-background: #1E2022;
  --secondary-color: #F8F9FA;
  --secondary-color-darker: lightgreen;
  --text-color: #202428;
  --text-color-light: white;
  --footer-text-color: #CDCDCE;
}

@import "../../../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: WorkSans-Regular;
  src: url("../fonts/work_sans/WorkSans-Regular.ttf");
}

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto.ttf");
}

@font-face {
  font-family: MavenPro-Regular;
  src: url("../fonts/maven_pro/MavenPro-Regular.ttf");
}

body {
  font-family: WorkSans-Regular, sans-serif !important;
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6, nav, button {
  font-family: MavenPro-Regular, sans-serif;
  letter-spacing: .1rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.375rem;
}

.button {
  padding: 3px 40px;
  border: 1px solid var(--primary-color);
  border-radius: 1px;
  font-size: 16px;
  margin: 10px 5px 10px 0;
  transition: background .8s;
  background: white;
  color: var(--primary-color);
}

.btn-cs-lg {
  font-size: 20px;
}

.btn-cs-sm {
  font-size: 8px;
}

.btn-cs-outline-primary {
  background: transparent;
  color: white;
  border: 1px solid white;
}

.btn-cs-outline-primary:hover {
  background: white;
  color: var(--text-color);
  border: 1px solid white;
}

.btn-cs-white:hover {
  background: white;
  color: var(--text-color);
  border: 1px solid white;
}

.input-main-border {
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
}

.input-main-border > .form-control {
  border: none;
}

.input-main-border-icon > .input-group-text {
  border: none !important;
}

.input-main-border:focus {
  border-color: var(--focus-color);
}

.input-main-border:active {
  border-color: var(--focus-color);
}

.input-main-border > .form-control:focus {
  outline: 0 !important;
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.nav-item > .active:hover {
  background: var(--secondary-color) !important;
}

.active {
  background: var(--primary-color);
  cursor: pointer;
  color: white;
}

.inactive {
  background: transparent;
  color: var(--primary-color);
}

.inactive:hover {
  background: var(--secondary-color);
}

.active:hover {
  background: var(--primary-color-darker) !important;
  color: white;
}


.button:hover {
  background: var(--primary-color);
  color: white;
}

.button:active, .button:focus {
  outline: 0;
}

.cs-border-white {
  border-bottom: 3px solid white;
}

@mixin cs-border {
  background-image: linear-gradient(white, white);
  background-size: 70px 3px;
  background-repeat: no-repeat;
  background-position: 20px 100%;
}

.cs-border-bottom-white {
  @include cs-border;
}

.cs-border-bottom-primary {
  @include cs-border;
  background-image: linear-gradient(var(--primary-color), var(--primary-color));
  background-position: 50% 100%;
}

.cs-border-bottom-primary-left {
  @include cs-border;
  background-image: linear-gradient(var(--primary-color), var(--primary-color));
  background-position: 20px 100%;

}

@mixin a-border {
  color: var(--text-color);
  padding: 15px 15px 5px 15px;
  cursor: pointer;
  text-decoration: none !important;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  font-size: .9rem;
  word-wrap: break-word;
}

.a-border {
  @include a-border
}

.a-border-no-size {
  color: var(--text-color);
  text-decoration: none !important;
  position: relative;
  cursor: pointer;
  display: inline-block;
  word-wrap: break-word;
  padding: 10px;
}

.a-border-no-size::before {
  content: '';
  position: absolute;
  background: var(--primary-color);
  height: 2px;
  top: 100%;
  left: 0;
  width: 0;
  transition: width 0.5s ease;
}

.a-border-no-size:hover::before {
  width: 100%;
}

.a-border-white {
  @include a-border;
  color: var(--footer-text-color);
  padding: 0;
}

.a-border-white::before {
  content: '';
  position: absolute;
  background: white;
  height: 2px;
  top: 100%;
  left: 0;
  width: 0;
  transition: width 0.5s ease;
}

.a-border-white:hover::before {
  width: 50%;
}

.a-border::before {
  content: '';
  position: absolute;
  background: var(--primary-color);
  height: 2px;
  top: 100%;
  left: 0;
  width: 0;
  transition: width 0.5s ease;
}

.a-border-active::before {
  content: '';
  position: absolute;
  background: var(--primary-color);
  height: 2px;
  top: 100%;
  left: 0;
  width: 100%;
  transition: width 0.5s ease;
}

.a-border:hover::before {
  width: 100%;
}

.video-holder {
  width: 80%;
  height: 50vh;
}

@media only screen and (max-width: 667px) {
  .video-holder {
    width: 80%;
    height: 30vh;
  }
}

@media only screen and (min-width: 280px) and (max-width: 667px) {
  .logo-img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .my-card {
    padding: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 5vh;
    height: 70vh;
  }

  .fixed-left {
    position: static;
    width: 100%;
  }

  .iframe-holder {
    width: 100%;
    height: 25vh;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1200px) {
  .iframe-holder {
    width: 100%;
    height: 30vh;
    object-fit: cover;
  }

  .logo-img {
    width: 100%;
    height: 15vh;
    object-fit: contain;
  }

  .my-card {
    padding: 0;
    box-shadow: none;
    margin-bottom: 0;
  }

  .fixed-left {
    position: fixed;
    top: 0;
    width: 30vw;
    height: 100vh;
  }
}

@media only screen and (min-width: 668px) and (max-width: 1199px) {
  .logo-img {
    width: 100%;
    height: 15vh;
    object-fit: contain;
  }

  .my-card {
    padding: 0;
    box-shadow: none;
    margin-bottom: 0;
  }

  .fixed-left {
    position: fixed;
    top: 0;
    width: 30vw;
    height: 100vh;
  }

  .iframe-holder {
    width: 100%;
    height: 25vh;
    object-fit: cover;
  }
}

.card {
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: .5rem;
}

.vr {
  border-left: 6px solid green;
  height: 100%;
}

.card-header {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.card-footer {
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.overlay {
  position: absolute;
  background-color: #1E2022;
  opacity: .5;
  height: 100%;
  width: 100%;
}

.h-15em {
  min-height: 15em;
}

.min-vh-75 {
  min-height: 75vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.jumbotron {
  border-radius: .5rem;
}

.div-button-white {
  background-color: white;
  cursor: pointer;
  transition: background-color .8s;
}

.div-button-white:hover {
  background-color: var(--secondary-color);
}

.span-button {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  cursor: pointer;
}

.span-button:hover {
  background-color: lightgray;
}

.span-cs-button {
  cursor: pointer;
}

.span-cs-button:hover {
  color: var(--primary-color);
}

.border-hover {
  cursor: pointer;
}

.border-hover:hover {
  border-color: var(--primary-color) !important;
}

.bg-primary-light{
  background-color: lightblue;
}